
        <template>
          <div class="vc-snippet-doc">
            <h1><a href="#%E6%8E%A7%E5%88%B6%E5%8F%B0%E6%89%93%E5%8D%B0%E4%BC%98%E5%8C%96">#</a> 控制台打印优化</h1>
<p>使用 $log 可以在控制台输出美观的日志信息
<img src="https://cdn.d2.pub/files/image-hosting/20180821133308.png" alt="" /></p>
<h2><a href="#%E8%83%B6%E5%9B%8A">#</a> 胶囊</h2>
<p>此方法打印一行“胶囊”样式的信息，即上图前四条，可打开控制台预览</p>
<vc-snippet>
                  <div slot="desc"><p>参数：</p>
</div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">div</span> &gt;</span>$log.capsule( `左侧文字`, `右侧文字`, `主题样式` )<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    mounted() {
        $log.capsule( <span class="hljs-string">`title`</span>, <span class="hljs-string">`primary`</span>, <span class="hljs-string">``</span> );
        $log.capsule( <span class="hljs-string">`title`</span>, <span class="hljs-string">`success`</span>, <span class="hljs-string">`success`</span> );
        $log.capsule( <span class="hljs-string">`title`</span>, <span class="hljs-string">`warning`</span>, <span class="hljs-string">`warning`</span> );
        $log.capsule( <span class="hljs-string">`title`</span>, <span class="hljs-string">`danger`</span>, <span class="hljs-string">`danger`</span> );
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h2><a href="#%E5%BD%A9%E8%89%B2%E6%96%87%E5%AD%97">#</a> 彩色文字</h2>
<vc-snippet>
                  <div slot="desc"><p>此方法可以随意组合打印文字的颜色顺序</p>
</div>
                  <vc-snippent-1 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">div</span> &gt;</span>
    $log.colorful([
      { text: 'H', type: 'default' },
      { text: 'e', type: 'primary' },
      { text: 'l', type: 'success' },
      { text: 'l', type: 'warning' },
      { text: 'o', type: 'danger' }
    ])
  <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span> &gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    mounted() {
        $log.colorful([
          { <span class="hljs-attr">text</span>: <span class="hljs-string">'H'</span>, <span class="hljs-attr">type</span>: <span class="hljs-string">'default'</span> },
          { <span class="hljs-attr">text</span>: <span class="hljs-string">'e'</span>, <span class="hljs-attr">type</span>: <span class="hljs-string">'primary'</span> },
          { <span class="hljs-attr">text</span>: <span class="hljs-string">'l'</span>, <span class="hljs-attr">type</span>: <span class="hljs-string">'success'</span> },
          { <span class="hljs-attr">text</span>: <span class="hljs-string">'l'</span>, <span class="hljs-attr">type</span>: <span class="hljs-string">'warning'</span> },
          { <span class="hljs-attr">text</span>: <span class="hljs-string">'o'</span>, <span class="hljs-attr">type</span>: <span class="hljs-string">'danger'</span> }
        ])
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <p>上面的代码效果为效果图中第五行</p>
<h2><a href="#%E5%BF%AB%E9%80%9F%E6%96%B9%E6%B3%95">#</a> 快速方法</h2>
<table>
<thead>
<tr>
<th>参数</th>
<th>备注</th>
</tr>
</thead>
<tbody>
<tr>
<td>$log.default</td>
<td>打印 default 样式的文字</td>
</tr>
<tr>
<td>$log.primary</td>
<td>打印 primary 样式的文字</td>
</tr>
<tr>
<td>$log.success</td>
<td>打印 success 样式的文字</td>
</tr>
<tr>
<td>$log.warning</td>
<td>打印 warning 样式的文字</td>
</tr>
<tr>
<td>$log.danger</td>
<td>打印 danger 样式的文字</td>
</tr>
</tbody>
</table>
<vc-snippet>
                  <div slot="desc"><p>示例：</p>
</div>
                  <vc-snippent-2 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">div</span> &gt;</span>
    $log.default('primary style') <span class="hljs-tag">&lt;<span class="hljs-name">br</span>&gt;</span>
    $log.primary('primary style')<span class="hljs-tag">&lt;<span class="hljs-name">br</span>&gt;</span>
    $log.success('success style')<span class="hljs-tag">&lt;<span class="hljs-name">br</span>&gt;</span>
    $log.warning('warning style')<span class="hljs-tag">&lt;<span class="hljs-name">br</span>&gt;</span>
    $log.danger('danger style')
<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span> &gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    mounted() {
        $log.default(<span class="hljs-string">'default style'</span>)
        
        $log.primary(<span class="hljs-string">'primary style'</span>)
        
        $log.success(<span class="hljs-string">'success style'</span>)
        
        $log.warning(<span class="hljs-string">'warning style'</span>)
        
        $log.danger(<span class="hljs-string">'danger style'</span>)
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h2>参考自：</h2>
<blockquote>
<p><a href="https://d2.pub/zh/doc/d2-admin/plugin/console.html#%E8%83%B6%E5%9B%8A">控制台打印优化</a></p>
</blockquote>

          </div>
        </template>
        <script>
           //
//
// //
//
//
//
//
//
//
//
//
//
// //
//
//
//
//
//
//
//
//
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v("$log.capsule( `左侧文字`, `右侧文字`, `主题样式` )")])}
var staticRenderFns = []

          const exportJavaScript =  {
  mounted() {
      $log.capsule( `title`, `primary`, `` );
      $log.capsule( `title`, `success`, `success` );
      $log.capsule( `title`, `warning`, `warning` );
      $log.capsule( `title`, `danger`, `danger` );
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-1":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v("\n  $log.colorful([\n    { text: 'H', type: 'default' },\n    { text: 'e', type: 'primary' },\n    { text: 'l', type: 'success' },\n    { text: 'l', type: 'warning' },\n    { text: 'o', type: 'danger' }\n  ])\n")])}
var staticRenderFns = []

          const exportJavaScript =  {
  mounted() {
      $log.colorful([
        { text: 'H', type: 'default' },
        { text: 'e', type: 'primary' },
        { text: 'l', type: 'success' },
        { text: 'l', type: 'warning' },
        { text: 'o', type: 'danger' }
      ])
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-2":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._v("\n    $log.default('primary style') "),_c('br'),_vm._v("\n    $log.primary('primary style')"),_c('br'),_vm._v("\n    $log.success('success style')"),_c('br'),_vm._v("\n    $log.warning('warning style')"),_c('br'),_vm._v("\n    $log.danger('danger style')\n")])}]

          const exportJavaScript =  {
  mounted() {
      $log.default('default style')
      
      $log.primary('primary style')
      
      $log.success('success style')
      
      $log.warning('warning style')
      
      $log.danger('danger style')
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         
       </style>